import { Button, Flex, Text } from '@chakra-ui/react'
import ClockIcon from '@components/icons/ClockIcon'
import { useAppSetupStore } from 'store/appSetup'
import Link from 'next/link'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/navigation'

interface Props {
  error?: Error & { digest?: string }
  pathBack?: string
  reset?: () => void
}

export default function Error({ pathBack, error, reset }: Props) {
  const { t } = useTranslation()
  const { back } = useRouter()
  const colors = useAppSetupStore((state) => state.colors)

  return (
    <Flex alignItems={'center'} direction={'column'} pt={'20vh'}>
      <ClockIcon color={colors.error[500]} />
      <Text
        fontSize={{ base: 'xl', xl: 'xxl' }}
        fontWeight={500}
        maxWidth={{ sm: '100%', lg: '600px' }}
        mt={{ base: 4, xl: 6 }}
        textAlign={'center'}
      >
        {error?.message || t('errorMessage.500.subtitle')}
      </Text>
      {pathBack ? (
        <Button
          as={Link}
          href={pathBack}
          mt={{ base: 6 }}
          px={{ base: 12 }}
          size="lg"
          variant="primary"
        >
          {t('buttons.return')}
        </Button>
      ) : (
        <Button
          mt={{ base: 6 }}
          px={{ base: 12 }}
          size="lg"
          variant="primary"
          onClick={reset || back}
        >
          {reset ? t('buttons.retry') : t('buttons.return')}
        </Button>
      )}
    </Flex>
  )
}
