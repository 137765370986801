'use client' // Error boundaries must be Client Components

import { GridItem } from '@chakra-ui/react'
import ErrorComponent from '@components/Layout/Detail/Error'

export default function Error({
  error,
  reset
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  return (
    <GridItem area={'main'} overflow="auto" px={{ base: 6, md: 8 }} py={{ base: 4, lg: 6, xl: 8 }}>
      <ErrorComponent error={error} reset={reset} />
    </GridItem>
  )
}
